<template>
  <div class="columns login_grid_height m-0">
    <div class="column is-6 left-section">
      <div class="inside-left">
        <Carousel />
      </div>
    </div>
    <div class="column is-6 overlay-bg">
      <div class="column is-6 login-form">
        <img class="company-logo" src="/images/rise_logo_red.png" alt="" />
        <p class="main-text is-size-2">Register New Account</p>
        <p class="child-text mb-4">Please enter your info to create account</p>
        <!-- <div class="buttons btn-container">
            <button
              class="btn click"
              @click.prevent="authProvider('google', 'register')"
            >
              <img src="../../../assets/images/google-logo.svg" alt="" />
              <p>Register with Google</p>
            </button>
            <button @click.prevent="msLogin('register')" class="btn click">
              <img src="../../../assets/images/microsoft-logo.svg" alt="" />
              <p>Register with Microsoft</p>
            </button>
          </div>
          <div class="line-break"><span>or</span></div> -->
        <validation-observer ref="setPassword" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <EmailInput
              :placeholder="'e.g: admin@roketin.com'"
              @email="emitEmail"
            ></EmailInput>
            <validation-provider
              name="password"
              rules="required|upCase|number|minPass:8"
              v-slot="{ errors }"
              immediate
              :bails="false"
            >
              <b-field label="Password">
                <b-tooltip
                  :triggers="['click']"
                  :auto-close="['outside', 'escape']"
                  type="is-dark"
                  expanded
                  multilined
                >
                  <template v-slot:content>
                    <div class="password-tooltip-content">
                      Please make sure that your password includes :
                      <p v-for="(message, m) in errorRules(errors)" :key="m">
                        <span v-if="message.isValid">
                          <img :src="urlValid" />
                        </span>
                        <span v-else><img :src="urlInvalid" /></span>
                        {{ message.text }}
                      </p>
                    </div>
                  </template>
                  <b-input
                    class="input-field password-eye"
                    type="password"
                    placeholder="password"
                    password-reveal
                    validate-vv-on="focus"
                    @input="errorRules"
                    v-model="data.password"
                  ></b-input>
                </b-tooltip>
              </b-field>
            </validation-provider>

            <validation-provider
              name="confirm password"
              rules="required|confirmed:password"
              v-slot="{ errors }"
            >
              <b-field
                label="Confirm Password"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
                class="input-field"
              >
                <b-input
                  v-model="data.passwordConfirmation"
                  class="password-eye"
                  type="password"
                  placeholder="password"
                  password-reveal
                ></b-input>
              </b-field>
            </validation-provider>
            <b-field class="is-flex is-justify-content-center login-cta mb-1">
              <b-button
                :loading="isLoading"
                native-type="submit"
                class="is-fullwidth"
              >
                Register
              </b-button>
            </b-field>
          </form>
        </validation-observer>

        <div class="register-cta is-flex">
          <router-link to="login" class="is-link">
            Already have an account?
            <span class="has-text-weight-semibold">Login Now</span>
          </router-link>
        </div>

        <div class="text-footers">
          <p>
            PT Roketin Kreatif Teknologi. Copyright
            {{ new Date().getFullYear() }}.
          </p>
        </div>
      </div>
    </div>

    <!-- Start Modal OTP -->
    <Modal
      :show="isModalOpen"
      :hide="closeModalRequest"
      :isEdit="isEditing"
      :class="'modal-allowed-capacity'"
    >
      <template #form>
        <div class="is-flex is-justify-content-center">
          <img src="../../../assets/images/email-sender.svg" alt="" />
        </div>
        <div class="has-text-centered mt-5">
          <h5>Please Verify Account</h5>
        </div>
        <div class="mx-6">
          <p class="modal-otp-text mx-6">
            Enter the link digit code we sent to your email address to verify
            your Rise account
          </p>
        </div>
        <div class="is-flex is-justify-content-center mt-5">
          <v-otp-input
            ref="otpInput"
            input-classes="otp-input"
            separator=""
            :num-inputs="6"
            :should-auto-focus="true"
            :is-input-num="true"
            v-model="code"
            @on-complete="handleOnComplete"
          />
        </div>
        <div class="buttons m-5">
          <b-button
            :loading="isLoading"
            @click="verifyOtp()"
            type="is-primary"
            expanded
          >
            Verify & Proceed
          </b-button>
        </div>
        <div class="has-text-centered mb-6">
          <a @click="resendCode()" class="resend-code">Resend Code</a>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '../../../components/Modals/Modal.vue'
import Carousel from '../../../components/Carousel.vue'
import { AuthMixin } from '../mixins/AuthMixin'
import EmailInput from '../../../components/EmailInput.vue'

export default {
  components: { Carousel, Modal, EmailInput },
  mixins: [AuthMixin],
  data() {
    return {
      isEditing: false,
      isModalOpen: false,
      isLoading: false,
      code: '',
      data: {
        email: '',
        password: '',
        passwordConfirmation: '',
      },
      tooltipMessages: [
        'Lowercase and uppercase letters',
        'at least one number',
        '8 or more characters',
      ],
      urlValid: require('@/assets/images/create-password/success.svg'),
      urlInvalid: require('@/assets/images/create-password/error.svg'),
      options: {
        minimizable: false,
        playerSize: 'standard',
        backgroundColor: '#fff',
        backgroundStyle: 'color',
        theme: {
          controlsView: 'standard',
          active: 'light',
          light: {
            color: '#3D4852',
            backgroundColor: '#fff',
            opacity: '0.7',
          },
          dark: {
            color: '#fff',
            backgroundColor: '#202020',
            opacity: '0.7',
          },
        },
      },
    }
  },

  methods: {
    /**
     * Emit email value from child component
     * @param {String} val - email input value
     */
    emitEmail(val) {
      this.data.email = val
    },
  },
}
</script>
